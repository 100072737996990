'use strict';

var R6MLangTerms = (function(undefined) {
  var terms = {},
    name = 'en',
    loadedLang = 'en',
    loadedDirection = 'LTR',
    translations = {},
    englishTerms = {
      general: {
        pageTitle: 'r6maps - eingabe.team - {mapName}',
        pageTitleSelectMap: 'r6maps - eingabe.team - Select a map',
        pageTitleStart: 'r6maps - eingabe.team',
        cameraViewCaption: '{floorName} Camera View',
        shortcutTip: 'Shortcut: {shortcut}',
        menu: 'Menu',
        about: 'About',
        languageHeader: 'Language',
        optionsHeader: 'Options',
        labelLosOpacity: 'Camera line-of-sight opacity',
        linkAbout: 'about/about.html',
        labelPercent: '{int}%', // according to: (0.12).toLocaleString('en', { style: 'percent' });
        labelLosDefault: '(Default)',
        labelLos105: '(Huh?)',
        labelLos110: '(Ludicrous!)',
        labelRoomLabelStyle: 'Room label style',
        labelNumberFloorsToDisplay: 'Number of floors to display',
        lockPanning: 'Lock panning',
        lockZooming: 'Lock zooming',
        fullScreen: 'Full screen',
        enableScreenshots: 'Enable screenshots',
        contributions: 'Contributions',
        supportSiteNote: 'Please consider showing your support with a small donation. :)',
        donateImg: 'https://www.paypalobjects.com/en_US/i/btn/btn_donate_LG.gif',
        latestUpdate: 'Feb 2017 updates',
        linkLatestUpdate: 'about/updates/2017-Feb/index.html'
      },
      sessions: {
        button: 'Sessions (BETA)'
      },
      roomLabelStyles: {
        Dark: 'Dark',
        Light: 'Light (Default)',
        DarkAndLarge: 'Large and Dark',
        LightAndLarge: 'Large and Light',
        DarkAndSmall: 'Small and Dark',
        LightAndSmall: 'Small and Light',
        DisplayNone: 'Turn Off'
      },
      floorDisplayOptions: {
        one: '1 - Full',
        two: '2 - Split',
        four: '4 - Grid'
      },
      selectMaps: {
        selectAMap: 'Select a map',
        homeLink: 'Select a map'
      },
      compass: {
        letterN: 'N',
        letterE: 'E',
        letterS: 'S',
        letterW: 'W'
      },
      languages: {
        en: 'English',
        de: 'Deutsch',
        fr: 'Français',
        he: 'עִברִית',
        ja: '日本語',
        kr: '한국어',
        mn: 'Монгол',
        pt: 'Português',
        ru: 'Русский',
        zh_cn: '简体中文',
        es: 'Español',
        pl: 'Polski'
      },
      floorNames: {
        subBasement: { full: 'Sub-Basement', short: 'B'},
        basement: { full: 'Basement', short: 'B' },
        firstFloor: { full: '1st Floor', short: '1' },
        secondFloor: { full: '2nd Floor', short: '2' },
        thirdFloor: { full: '3rd Floor', short: '3' },
        fourthFloor: { full: '4th Floor', short: '4' },
        roof: { full: 'Roof', short: 'R' }
      },
      objectives: {
        bombShortA: 'A',
        bombShortB: 'B',
        bomb: 'Bomb',
        hostageShort: 'H',
        hostage: 'Hostage',
        secureShort: 'S',
        secure: 'Secure',
        showAll: 'Show All'
      },
      legend: {
        breakableWalls: 'Breakable walls',
        breakableFloorTraps: 'Breakable floor traps',
        ceilingHatches: 'Ceiling hatches',
        lineOfSightWalls: 'Line of sight or half walls',
        lineOfSightFloors: 'Line of sight floors',
        droneTunnels: 'Drone tunnels',
        objectives: 'Objectives',
        insertionPoints: 'Insertion points',
        securityCameras: 'Security cameras',
        skylights: 'Skylights',
        onFloorAboveOrBelow: 'On floor above or below',
        cameraLineOfSight: 'Camera line of sight',
        ladders: 'Ladders'
      },
      spawnPoints: {
        a: 'A',
        b: 'B',
        c: 'C',
        d: 'D',
        e: 'E'
      },
      stats: {
        title: 'r6maps - eingabe.team - Stats',
        titleBeta: 'r6maps - eingabe.team - Stats BETA',
        headerMain: 'Stats',
        headerMainBeta: 'Stats BETA',
        headerFilters: 'Filters',
        headerMap: 'Map Stats',
        headerOperators: 'Operators',
        headerAboutAndOptions: 'About/Options',
        headerWinReasons: '{role} Win Reasons',
        labelSeason: 'Season',
        labelPlatform: 'Platform',
        labelMap: 'Map',
        labelGameMode: 'Mode',
        labelObjectiveLocation: 'Location',
        allOption: 'All',
        seasonWithNumber: 'Season {0}',
        objectiveRoomDivider: ' / ',
        ellipsis: '...',
        loadButtonText: 'Load Stats',
        averageRoundLength: 'Average round length',
        numberSeconds: '{num} seconds',
        totalRoundsPlayed: 'Total rounds played',
        numberRounds: '{num} rounds',
        tableHeaderAttackers: 'Attackers',
        tableHeaderAttackersSingular: 'Attacker',
        tableHeaderDefenders: 'Defenders',
        tableHeaderDefendersSingular: 'Defender',
        tableHeaderName: 'Name',
        tableHeaderPickRate: 'Pick Rate',
        tableHeaderWinRate: 'Win Rate',
        tableHeaderSurvivalRate: 'Survival Rate',
        tableHeaderKillsPerDeath: 'Kills:Death',
        tableHeaderKillsPerRound: 'Kills:Round',
        tableHeaderTotalRounds: 'Total Rounds',
        tableHeaderAllRanks: 'All Ranks',
        tableNoteHeader: 'Notes:',
        tableNotePickRate: 'Pick rates by rank are approximate and based on the relative number of rounds played.',
        tableNoteHeaders: 'Click on headers for charts and subheaders for sort order.',
        tableNoteWarningText: 'Orange text indicates a less reliable stat based on very few rounds played.',
        tableFewRoundsNote: 'Less reliable (very few rounds played)',
        averagesAndTotals: 'Averages/Totals',
        percentageFormat: '{num}%',
        instructions: 'Select filter options and hit \'Load Stats\' to continue.',
        noResults: 'No results found.  Please adjust the filters or try again later.',
        error: 'There was an error trying to load stats.  Please try again later.',
        chartHeader: '{stat} for {role}',
        loadedInfoLine1AllPlatforms: 'Showing Stats for {season} on All Platforms',
        loadedInfoLine1SinglePlatform: 'Showing Stats for {season} on {platform}',
        loadedInfoLine2AllMapsAllModes: 'All Maps | All Modes',
        loadedInfoLine2SingleMapAllModes: '{map} | All Modes',
        loadedInfoLine2AllMapsSingleMode: 'All Maps | {mode}',
        loadedInfoLine2SingleMapSingleModeAllLocations: '{map} | {mode} | All Locations',
        loadedInfoLine2SingleMapSingleModeSingleLocation: '{map} | {mode} | {location}',
        aboutLinksHeader: 'Links',
        aboutR6MapsHome: 'R6Maps.com Home',
        aboutR6MapsAbout: 'R6Maps.com About',
        aboutBasedOnUbisoft: 'Ubisoft Data Peek Series',
        winRatesForMap: 'Win rates for {map}',
        detailedStatsLink: 'See detailed stats'
      },
      statsRoundWinReasons: {
        allTeamsDead: 'All teams dead',
        attackersEliminated: 'Enemy team killed',
        attackersKilledHostage: 'Hostage killed',
        attackersSurrendered: 'Surrender',
        bombDeactivated_OneBomb: 'Bomb deactivated',
        bombExploded: 'Bomb exploded',
        defendersEliminated: 'Enemy team killed',
        defendersKilledHostage: 'Hostage killed',
        defendersSurrendered: 'Surrender',
        defuserDeactivated: 'Defuser deactivated',
        hostageExtracted: 'Hostage extracted',
        noEnemies: 'No enemies',
        objectiveCaptured: 'Objective secured',
        objectiveProtected: 'Objective protected',
        timeExpired: 'Time expired'
      },
      toggle: {
        labels: {full: 'Toggle Labels', short: 'Labels', shortcut: ['t','T']}
      },
      ranks: {
        unranked: 'Unranked',
        copper: 'Copper',
        bronze: 'Bronze',
        silver: 'Silver',
        gold: 'Gold',
        platinum: 'Platinum',
        diamond: 'Diamond'
      },
      platforms: {
        pc: 'PC',
        xboxone: 'Xbox One',
        ps4: 'PS4'
      },
      seasons: {
        1: 'Black Ice',
        2: 'Dust Line',
        3: 'Skull Rain',
        4: 'Red Crow',
        5: 'Velvet Shell',
        6: 'Operation Health',
        7: 'Blood Orchid',
        8: 'White Noise',
        9: 'Chimera',
        10: 'Para Bellum',
        11: 'Grim Sky',
        12: 'Wind Bastion',
        13: 'Burnt Horizon',
        14: 'Phantom Sight',
        15: 'Ember Rise',
        16: 'Shifting Tides'
      },
      operators: {
        amaru: 'Amaru',
        ash: 'Ash',
        bandit: 'Bandit',
        blackbeard: 'Blackbeard',
        blitz: 'Blitz',
        buck: 'Buck',
        capitao: 'Capitao',
        castle: 'Castle',
        caveira: 'Caveira',
        doc: 'Doc',
        echo: 'Echo',
        ela: 'Ela',
        frost: 'Frost',
        fuze: 'Fuze',
        gignRecruit: 'GIGN Recruit',
        glaz: 'Glaz',
        goyo: 'Goyo',
        gridlock: 'Gridlock',
        gsg9Recruit: 'GSG9 Recruit',
        hibana: 'Hibana',
        iq: 'IQ',
        jackal: 'Jackal',
        jager: 'Jager',
        kaid: 'Kaid',
        kali: 'Kali',
        kapkan: 'Kapkan',
        lesion: 'Lesion',
        mira: 'Mira',
        montagne: 'Montagne',
        mozzie: 'Mozzie',
        mute: 'Mute',
        nokk: 'Nøkk',
        nomad: 'Nomad',
        pulse: 'Pulse',
        rook: 'Rook',
        sasRecruit: 'SAS Recruit',
        sledge: 'Sledge',
        smoke: 'Smoke',
        spetsnazRecruit: 'Spetsnaz Recruit',
        swatRecruit: 'SWAT Recruit',
        tachanka: 'Lord Tachanka',
        thatcher: 'Thatcher',
        thermite: 'Thermite',
        twitch: 'Twitch',
        valkyrie: 'Valkyrie',
        wamai: 'Wamai',
        warden: 'Warden',
        ying: 'Ying'
      },
      mapNames: {
        bank: 'Bank',
        bartlett: 'Bartlett U.',
        border: 'Border',
        chalet: 'Chalet',
        club: 'Club House',
        consulate: 'Consulate',
        coastline: 'Coastline',
        favela: 'Favela',
        fortress: 'Fortress',
        hereford: 'Hereford',
        house: 'House',
        kanal: 'Kanal',
        kafe: 'Kafe Dostoyevsky',
        oregon: 'Oregon',
        outback: 'Outback',
        plane: 'Plane',
        skyscraper: 'Skyscraper',
        themepark: 'Theme Park',
        tower: 'Tower',
        villa: 'Villa',
        yacht: 'Yacht'
      },
      mapRooms: {
        bank: {
          spawnBoulevard: 'Boulevard',
          spawnBackAlley: 'Back Alley',
          printerRoom: 'Printer Room',
          parkingLot: 'Parking Lot',
          boulevard: 'Boulevard',
          jewelryFront: 'Jewelry Front',
          plaza: 'Plaza',
          mainEntrance: 'Main Entrance',
          garageRamp: 'Garage<br/>Ramp',
          exteriorParking: 'Exterior Parking',
          garageRoof: 'Garage<br/>Roof',
          alleyAccess: 'Alley Access',
          backAlleyRooftop: 'Back Alley<br/>Rooftop',
          backAlley: 'Back Alley',
          highRoof: 'High Roof',
          lowRoof: 'Low Roof',
          vault: 'Vault',
          goldVault: 'Gold<br/>Vault',
          serverRoomStairs: 'Server Room Stairs',
          serverRoom: 'Server Room',
          CCTVRoom: 'CCTV<br/>Room',
          loadingDock: 'Loading Dock',
          secureHallway: 'Secure<br/>Hallway',
          sewer: 'Sewer',
          lockers: 'Lockers',
          vaultLobby: 'Vault Lobby',
          vaultEntrance: 'Vault<br/>Entrance',
          mainStairway: 'Main<br/>Stairway',
          bankGarage: 'Bank Garage',
          elevators: 'Elevators',
          tellersOffice: 'Tellers\'<br/>Office',
          archives: 'Archives',
          tellers: 'Tellers',
          loanOffice: 'Loan Office',
          officeHallway: 'Office Hallway',
          skylightStairwell: 'Skylight<br/>Stairwell',
          lobby: 'Lobby',
          openArea: 'Open Area',
          staffRoom: 'Staff Room',
          electricalRoom: 'Electrical<br/>Room',
          adminOffice: 'Admin Office',
          ATMs: 'ATMs',
          executiveHallway: 'Executive<br/>Hallway',
          frontDesk: 'Front<br/>Desk',
          executiveLounge: 'Executive<br/>Lounge',
          CEOOffice: 'CEO<br/>Office',
          janitorCloset: 'Janitor Closet',
          hallway: 'Hallway',
          terrace: 'Terrace',
          stockTradingRoom: 'Stock<br/>Trading<br/>Room',
          conferenceRoom: 'Conference<br/>Room'
        },
        bartlett: {
          archwayHall: 'Archway Hall',
          archwaylHallway: 'Archway Hallway',
          backAlley: 'Back Alley',
          bathroom: 'Bath-<br/>room',
          campusField: 'Campus<br/>Field',
          classroom: 'Classroom',
          coatRoom: 'Coat<br/>Room',
          compassHallway: 'Compass Hallway',
          courtyard: 'Courtyard',
          centralHallway: 'Central<br/>Hallway',
          diningRoom: 'Dining Room',
          eastBalcony: 'East Balcony<br/>(not shown)',
          eastCorridor: 'East<br/>Corridor',
          eastStairs: 'East<br/>Stairs',
          festival: 'Festival',
          frontEntrance: 'Front Entrance',
          frontOffice: 'Front<br/>Office',
          frontPatio: 'Front Patio',
          gardenPass: 'Garden<br/>Pass',
          kitchen: 'Kitchen',
          lobby: 'Lobby',
          lounge: 'Lounge',
          lowerLibrary: 'Lower<br/>Library',
          mainGate: 'Main<br/>Gate',
          mainOffice: 'Main Office',
          modelHall: 'Model Hall',
          pantry: 'Pantry',
          parking: 'Parking',
          pianoRoom: 'Piano Room',
          readingRoom: 'Reading Room',
          roof: 'Roof',
          rowingMuseum: 'Rowing<br/>Museum',
          serviceRoom: 'Service<br/>Room',
          terrace: 'Terrace',
          trophyRoom: 'Trophy<br/>Room',
          upperLibrary: 'Upper<br/>Library',
          vistaHallway: 'Vista<br/>Hallway',
          westBalcony: 'West Balcony<br/>(not shown)',
          westCorridor: 'West<br/>Corridor'
        },
        border: {
          armoryLockers: 'Armory<br/>Lockers',
          tellers: 'Tellers',
          ventilationRoom: 'Venti-<br/>lation<br/>Room',
          exitHallway: 'Exit<br/>Hallway',
          westMain: 'West Main',
          supplyCorridor: 'Supply<br/>Corridor',
          detention: 'Detention',
          customsInspection: 'Customs Inspection',
          customsDesk: 'Customs<br/>Desk',
          centralStairs: 'Central Stairs',
          mainStairs: 'Main Stairs',
          serverRoom: 'Server Room',
          supplyRoom: 'Supply Room',
          workshop: 'Work-<br/>shop',
          mainLobby: 'Main<br/>Lobby',
          bathroom: 'Bath-<br/>room',
          waitingRoom: 'Waiting<br/>Room',
          eastStairs: 'East<br/>Stairs',
          passportCheck: 'Passport<br/>Check',
          archives: 'Archives',
          offices: 'Offices',
          officesHallway: 'Offices Hallway',
          fountain: 'Fountain',
          mainHallway: 'Main<br/>Hallway',
          armoryDesk: 'Armory<br/>Desk',
          securityRoom: 'Security<br/>Room',
          breakRoom: 'Break Room',
          spawnEastVehicleEntrance: 'East Vehicle Entrance',
          spawnValley: 'Valley',
          spawnWestVehicleExit: 'West Vehicle Exit',
          westTower: 'West<br/>Tower',
          pedestrianExit: 'Pedestrian Exit',
          valley: 'Valley',
          parkingLotEntrance: 'Parking Lot<br/>Entrance',
          parkingLot: 'Parking Lot',
          westRoad: 'West Road',
          vehicleCustoms: 'Vehicle Customs',
          crashScene: 'Crash Scene',
          eastRoad: 'East Road',
          pedestrianEntrance: 'Pedestrian<br/>Entrance',
          pedestrianCustoms: 'Pedestrian<br/>Customs',
          watchTower: 'Watch<br/>Tower',
          eastAlley: 'East Alley',
          parkingLotAlley: 'Parking<br/>Lot<br/>Alley',
          northBalcony: 'North Balcony',
          eastBalcony: 'East<br/>Balcony',
          westBalcony: 'West<br/>Balcony',
          southBalcony: 'South Balcony',
          roof: 'Roof'
        },
        chalet: {
          spawnFrontYard: 'Front Yard',
          spawnCampfire: 'Campfire',
          spawnCliffside: 'Cliffside',
          spawnLakeside: 'Lakeside',
          libraryStairs: 'Library<br/>Stairs',
          snowmobileGarageCorridor: 'Snowmobile Garage Corridor',
          snowmobileGarage: 'Snowmobile<br/>Garage',
          greatRoomStairs: 'Great<br/>Room<br/>Stairs',
          storageRoom: 'Storage<br/>Room',
          wineCellar: 'Wine<br/>Cellar',
          wineStock: 'Wine<br/>Stock',
          basementHallway: 'Basement<br/>Hallway',
          backyardStairs: 'Backyard<br/>Stairs',
          mainStairs: 'Main<br/>Stairs',
          mainGarage: 'Main Garage',
          garageEntrance: 'Garage<br/>Entrance',
          westEntrance: 'West<br/>Entrance',
          gamingRoomHallway: 'Gaming Room<br/>Hallway',
          gamingRoom: 'Gaming<br/>Room',
          bar: 'Bar',
          greatRoom: 'Great Room',
          diningRoom: 'Dining Room',
          mainEntrance: 'Main<br/>Entrance',
          trophyRoom: 'Trophy Room',
          kitchenHallway: 'Kitchen<br/>Hallway',
          kitchen: 'Kitchen',
          libraryHallway: 'Library Hallway',
          libraryEntrance: 'Library<br/>Entrance',
          library: 'Library',
          bedroomTerrace: 'Bedroom<br/>Terrace',
          fireplaceHallway: 'Fireplace<br/>Hallway',
          bedroomHallway: 'Bedroom<br/>Hallway',
          masterBathroom: 'Master<br/>Bathroom',
          masterBedroom: 'Master Bedroom',
          office: 'Office',
          woodenTrail: 'Wooden<br/>Trail',
          campfireWood: 'Campfire Wood',
          backyard: 'Backyard',
          gazeebo: 'Gazebo',
          cliffsideStairs: 'Cliffside<br/>Stairs',
          cliffsideWoods: 'Cliffside Woods',
          backyardPatio: 'Backyard Patio',
          officeBalcony: 'Office<br/>Balcony',
          helipadTrail: 'Helipad<br/>Trail',
          helipad: 'Helipad',
          frontYardPatio: 'Front Yard<br/>Patio',
          frontYard: 'Front Yard',
          bathroomBalcony: 'Bathroom<br/>Balcony',
          libraryBalcony: 'Library<br/>Balcony',
          bedroomBalcony: 'Bedroom<br/>Balcony',
          snowmobiles: 'Snowmobiles',
          solarium: 'Solarium',
          fireplace: 'Fireplace',
          westMainEntrance: 'West Main<br/>Entrance',
          pianoRoom: 'Piano Room',
          mezzanine: 'Mezzanine',
          trophyStairs: 'Trophy Stairs',
          diningHallway: 'Dining<br/>Hallway',
          mudroom: 'Mudroom',
          barStock: 'Bar Stock',
          fireplaceStairs: 'Fireplace Stairs',
          blueHallway: 'Blue Hallway',
          carGarage: 'Car Garage',
          mainGarageEntrance: 'Main<br/>Garage<br/>Entrance',
          westMainStairs: 'West-Main<br/>Stairs',
          cliffside: 'Cliffside',
          campfire: 'Campfire'
        },
        club: {
          // EXT
          mainGate: 'Main Gate',
          junkyard: 'Junkyard',
          vipParking: 'VIP Parking',
          parking: 'Parking',
          kennels: 'Kennels',
          warehouse: 'Warehouse',
          shippingDock: 'Shipping Dock',
          recreationArea: 'Recreation<br/>Area',
          trash: 'Trash',
          graffitiArea: 'Graffiti Area',
          constructionSite: 'Construction<br/>Site',

          // Stairs
          easternStairs: 'Eastern<br/>Stairs',
          blueStairs: 'Blue<br/>Stairs',
          centralStairs: 'Central<br/>Stairs',

          // Basement
          oilPit: 'Oil Pit',
          utilityRoom: 'Utility<br/>Room',
          memorialRoom: 'Memorial Room',
          church: 'Church',
          basementHallway: 'Basement Hallway',
          arsenalRoom: 'Arsenal<br/>Room',
          escapeTunnel: 'Escape<br/>Tunnel',
          container: 'Container',

          // 1F
          garage: 'Garage',
          sideEntrance: 'Side Entrance',
          changingRoom: 'Changing<br/>Room',
          stripClub: 'Strip Club',
          lobby: 'Lobby',
          frontPorch: 'Front Porch',
          lounge: 'Lounge',
          junkyardEntrance: 'Junkyard<br/>Entrance',
          garageStorage: 'Garage<br/>Storage',
          poolTable: 'Pool Table',
          bar: 'Bar',
          stage: 'Stage',
          stockRoom: 'Stock Room',
          westernHallway: 'Western<br/>Hallway',
          toilets: 'Toilets',
          centralHallway: 'Central Hallway',
          kitchen: 'Kitchen',
          kitchenEntrance: 'Kitchen<br/>Entrance',

          // 2F
          westernRoof: 'Western Roof',
          centralSubroof: 'Central<br/>Sub-roof',
          cctvRoom: 'CCTV Room',
          easternSubroof: 'Eastern<br/>Sub-roof',
          gym: 'Gym',
          bedroom: 'Bedroom',
          construction: 'Construction',
          cashRoom: 'Cash<br/>Room',
          balcony: 'Balcony',
          bedroomHallway: 'Bedroom Hallway',
          bathroom: 'Bathroom',
          secretStash: 'Secret<br/>Stash',
          logisticOffice: 'Logistic<br/>Office',

          // Roof
          easternRoof: 'Eastern Roof',
          centralRoof: 'Central Roof'

        },
        consulate: {
          spawnRiotBarricade: 'Riot Barricade',
          spawnPoliceLine: 'Police Line',
          spawnGasStation: 'Gas Station',
          spawnSideEntrance: 'Side Entrance',
          exitStairs: 'Exit<br/>Stairs',
          garage: 'Garage',
          basementCorridor: 'Basement Corridor',
          securityRoom: 'Security Room',
          cafeteria: 'Cafeteria',
          mainStairs: 'Main Stairs',
          lockerHallway: 'Locker<br/>Hallway',
          serviceStairs: 'Service<br/>Stairs',
          electricRoom: 'Electric<br/>Room',
          storageRoom: 'Storage<br/>Room',
          archives: 'Archives',
          archivesCorridor: 'Archives<br/>Corridor',
          pressRoom: 'Press Room',
          westCorridor: 'West Corridor',
          publicBathroom: 'Public<br/>Bathroom',
          antechamber: 'Antechamber',
          lobby: 'Lobby',
          eastCorridor: 'East<br/>Corridor',
          tellers: 'Tellers',
          visaOffice: 'Visa Office',
          visaEntrance: 'Visa<br/>Entrance',
          frontDoor: 'Front<br/>Door',
          balcony: 'Balcony',
          copyRoom: 'Copy Room',
          cabinet: 'Cabinet',
          administrationOffice: 'Administration<br/>Office',
          breakRoom: 'Break<br/>Room',
          frontOffice: 'Front Office',
          meetingRoom: 'Meeting<br/>Room',
          hallway: 'Hallway',
          consulFrontDesk: 'Consul<br/>Front<br/>Desk',
          privateBathroom: 'Private<br/>Bathroom',
          waitingRoom: 'Waiting<br/>Room',
          consulateOffice: 'Consulate<br/>Office',
          garageWay: 'Garage Way',
          courtyard: 'Courtyard',
          backCourtyard: 'Back Courtyard',
          sideEntrance: 'Side Entrance',
          dumpster: 'Dumpster',
          parking: 'Parking',
          gardens: 'Gardens',
          fountain: 'Fountain',
          emergencyExit: 'Emergency<br/>Exit',
          garageRoof: 'Garage<br/>Roof',
          memorialGarden: 'Memorial Garden',
          policeLine: 'Police Line',
          riotBarracade: 'Riot Barracade',
          eastFrontYard: 'East Front Yard',
          westFrontYard: 'West Front Yard',
          frontAlley: 'Front Alley',
          buildingRoof: 'Building Roof'
        },
        coastline: {
          aquarium: 'Aquarium',
          backAlley: 'Back<br/>Alley',
          balcony: 'Balcony (not shown)',
          bathroom: 'Bathroom',
          billiardsRoom: 'Billiards Room',
          blueBar: 'Blue Bar',
          cantina: 'Cantina',
          courtyard: 'Courtyard',
          djBooth: 'DJ Booth (not shown)',
          garageRoof: 'Garage Roof',
          hallOfFame: 'Hall of Fame',
          hallway: 'Hallway',
          hookahDeck: 'Hookah<br/>Deck<br/>(not shown)',
          hookahLounge: 'Hookah Lounge',
          kitchen: 'Kitchen',
          mainEntrance: 'Main<br/>Entrance',
          mainLobby: 'Main<br/>Lobby',
          northStairs: 'North<br/>Stairs',
          office: 'Office',
          penthouse: 'Penthouse',
          pool: 'Pool',
          poolEntrance: 'Pool Entrance',
          poolSide: 'Pool Side',
          rooftop: 'Rooftop',
          ruins: 'Ruins',
          securityRoom: 'Security<br/>Room',
          serviceEntrance: 'Service<br/>Entrance',
          southHallway: 'South Hallway',
          southPromenade: 'South Promenade',
          southStairs: 'South<br/>Stairs',
          sunriseBar: 'Sunrise Bar',
          sunRoom: 'Sun Room',
          theater: 'Theater',
          terrace: 'Terrace',
          toilets: 'Toilets',
          vipLounge: 'VIP Lounge',
          walkway: 'Walkway'
        },
        favela: {
          roof: 'Roof',
          backAlley: 'Back Alley',
          footballField: 'Football<br/>Field',
          market: 'Market',
          marketAlley: 'Market<br/>Alley',
          schoolRoof: 'School<br/>Roof',
          street: 'Street',
          rooftops: 'Rooftops',
          courtyard: 'Courtyard',
          marketRoof: 'Market Roof',
          electrical: 'Electrical',
          trashChuteStairs: 'Trash Chute<br/>Stairs',
          redStairs: 'Red<br/>Stairs',
          coinFarm: 'Coin Farm',
          storage: 'Storage',
          armory: 'Armory',
          redCorridor: 'Red Corridor',
          mezzanineStairs: 'Mezzanine<br/>Stairs',
          bunks: 'Bunks',
          office: 'Office',
          hackerDen: 'Hacker<br/>Den',
          greenBathroom: 'Green<br/>Bathroom',
          greenApartment: 'Green Apartment',
          greenFoosball: 'Green<br/>Foosball',
          blueApartment: 'Blue<br/>Apartment',
          blueBedroom: 'Blue Bedroom',
          blueKitchen: 'Blue<br/>Kitchen',
          laundryHall: 'Laundry<br/>Hall',
          footballHall: 'Football<br/>Hall',
          pinkApartment: 'Pink Apartment',
          pinkKitchen: 'Pink Kitchen',
          pinkSolarium: 'Pink<br/>Solarium',
          tunnel: 'Tunnel',
          schoolyard: 'Schoolyard',
          solariumRoof: 'Solarium Roof',
          armoryBalcony: 'Armory<br/>Balcony',
          storageBalcony: 'Storage Balcony'
        },
        fortress: {
          // Spawns
          mainGate: 'Main Gate',
          parking: 'Parking',
          garden: 'Garden',
          stable: 'Stable',
          // Within each section going from south west, east then north
          // EXT
          gardenPath: 'Garden Path',
          stablePath: 'Stable Path',
          cliffside: 'Cliffside',
          courtyardPath: 'Courtyard<br/>Path',
          cannonOverlook: 'Cannon<br/>Overlook',
          unloadingZone: 'Unloading Zone',
          frontEntrance: 'Front Entrance',
          // 1F
          laundryRoom: 'Laundry Room',
          hammam: 'Hammam',
          showers: 'Showers',
          hammamHallway: 'Hammam<br/>Hallway',
          cafeteria: 'Cafeteria',
          infirmary: 'Infirmary',
          kitchen: 'Kitchen',
          changingRoom: 'Changing Room',
          mainHallway: 'Main Hallway',
          waitingRoom: 'Waiting Room',
          sittingRoom: 'Sitting Room',
          diningRoom: 'Dining Room',
          courtyard: 'Courtyard',
          musicRoom: 'Music Room',
          lobby: 'Lobby',
          courtyardLadder: 'Courtyard<br/>Ladder',
          office: 'Office',
          // here because this only appears for 1f
          lobbyStairs: 'Lobby<br/>Stairs',
          oldTowerStairs: 'Old Tower<br/>Stairs',
          // 2F
          hammamRoof: 'Hammam<br/>Roof',
          shishaLounge: 'Shisha Lounge',
          shishaHallway: 'Shisha Hallway',
          centralStairsHall: 'Central<br/>Stairs<br/>Hall',
          dormitory: 'Dormitory',
          gamesRoom: 'Games<br/>Room',
          briefingRoom: 'Briefing Room',
          courtyardBalcony: 'Courtyard<br/>Balcony',
          museum: 'Museum',
          bathroom: 'Bathroom',
          commandersOffice: 'Commander\'s Office',
          bedroom: 'Bedroom',
          courtyardTower: 'Courtyard<br/>Tower',
          towerStairs: 'Tower<br/>Stairs',
          towerHallway: 'Tower Hallway',
          towerRenovation: 'Tower<br/>Renovation',
          // Roof
          oldRoof: 'Old<br/>Roof',
          oldTower: 'Old<br/>Tower',
          modernRoof: 'Modern<br/>Roof',
          westTower: 'West<br/>Tower',
          eastTower: 'East<br/>Tower',
          guardTower: 'Guard<br/>Tower',
          // Stairs
          centralStairs: 'Central<br/>Stairs'
        },
        hereford: {
          // EXT/Spawn
          controlTower: 'Control Tower',
          street: 'Street',
          spitfireCourtyard: 'Spitfire Courtyard',
          westCourtyard: 'West<br/>Courtyard',
          eastCourtyard: 'East<br/>Courtyard',
          barnyard: 'Barnyard',
          shootingRange: 'Shooting Range',
          // Stairs
          mainStairs: 'Main<br/>Stairs',
          blueStairs: 'Blue<br/>Stairs',
          // 4F/Roof
          rooftop: 'Rooftop',
          // 3F
          tractorStorage: 'Tractor<br/>Storage',
          attic: 'Attic',
          ammoStorage: 'Ammo<br/>Storage',
          // 2F
          balcony: 'Balcony',
          loomRoom: 'Loom<br/>Room',
          laundryRoom: 'Laundry<br/>Room',
          garageRoof: 'Garage<br/>Roof',
          loomCloset: 'Loom<br/>Closet',
          blueHallway: 'Blue<br/>Hallway',
          masterBedroom: 'Master<br/>Bedroom',
          kidsRoom: 'Kids<br/>Room',
          bathroom: 'Bathroom',
          sewingRoom: 'Sewing<br/>Room',
          // 1F
          prepArea: 'Prep Area',
          kitchen: 'Kitchen',
          diningRoom: 'Dining<br/>Room',
          garage: 'Garage',
          kitchenEntrance: 'Kitchen<br/>Entrance',
          pianoRoom: 'Piano<br/>Room',
          livingRoom: 'Living<br/>Room',
          foyer: 'Foyer',
          // Basement
          eastTunnel: 'East<br/>Tunnel',
          fermentationChamber: 'Fermentation<br/>Chamber',
          generatorRoom: 'Generator<br/>Room',
          workshop: 'Workshop',
          mainHallway: 'Main<br/>Hallway',
          westTunnel: 'West<br/>Tunnel',
          brewery: 'Brewery',
          barrelStorage: 'Barrel<br/>Storage',
          barn: 'Barn'
        },
        house: {
          // EXT
          // Construction Site and River Docks are commented out because they still exist in Training Grounds but not anywhere else.
          // spawnConstructionSite: 'Construction Site',
          // spawnRiverDocks: 'River Docks',
          spawnFrontStreet: 'Front Street',
          spawnAPCArea: 'APC Area',
          spawnSideStreet: 'Side Street',
          sideStreet: 'Side<br/>Street',
          garageEntrance: 'Garage<br/>Entrance',
          garageSide: 'Garage<br/>Side',
          apcArea: 'APC Area',
          treeHouseAlley: 'Treehouse<br/>Alley',
          patio: 'Patio',
          basementStairs: 'Basement<br/>Stairs',
          frontYard: 'Front Yard',
          frontStreet: 'Front Street',
          frontBalcony: 'Front Balcony',
          readingRoomBalcony: 'Reading R.<br/>Balcony',
          backPorch: 'Back Porch',
          backPorchTop: 'Back Porch<br/>Top',
          // Stairs
          kitchenStairs: 'Kitchen<br/>Stairs',
          lobbyStairs: 'Lobby<br/>Stairs',
          backStairs: 'Back<br/>Stairs',
          redStairs: 'Red<br/>Stairs',
          // Basement
          depot: 'Depot',
          gym: 'Gym',
          laundryRoom: 'Laundry<br/>Room',
          garage: 'Garage',
          // 1F
          gameRoom: 'Game<br/>Room',
          backEntrance: 'Back Entrance',
          lobby: 'Lobby',
          kitchen: 'Kitchen',
          office: 'Office',
          diningRoom: 'Dining<br/>Room',
          musicRoom: 'Music Room',
          tvRoom: 'TV Room',
          connector: 'Connector',
          // 2F
          pinkRoom: 'Pink Room',
          girlsWalkIn: 'Girls\'<br/>Walk In',
          carRoom: 'Car Room',
          playRoom: 'Play Room',
          upperHallway: 'Upper<br/>Hallway',
          walkIn: 'Walk In',
          masterBedroom: 'Master Bedroom',
          readingRoom: 'Reading Room',
          bathroom: 'Bathroom',
          bathroomHallway: 'Bathroom Hallway',
          // 3F/Roof
          rooftop: 'Rooftop'
        },
        kanal: {
          // EXT
          sailboats: 'Sailboats',
          middleRoad: 'Middle Road',
          parking: 'Parking',
          frontLawn: 'Front Lawn',
          lockGateTunnel: 'Lock Gate<br/>Tunnel',
          forkliftAlley: 'Forklift<br/>Alley',
          parkingEntrance: 'Parking Entrance',
          parkingAlley: 'Parking Alley',
          lockGate: 'Lock<br/>Gate',
          constructionSite: 'Construction<br/>Site',
          garage: 'Garage',
          divingAlcove: 'Diving<br/>Alcove',
          quayContainers: 'Quay Containers',
          floatingDock: 'Floating Dock',
          docksBridge: 'Docks Bridge',
          // Stairs
          // basementStairs: 'Basement Stairs',
          whiteStairs: 'White<br/>Stairs',
          redStairs: 'Red<br/>Stairs',
          greenStairs: 'Green<br/>Stairs',
          yellowStairs: 'Yellow<br/>Stairs',
          // printerStairs: 'Printer<br/>Stairs',
          blueStairs: 'Blue<br/>Stairs',
          // 3F/Roof
          controlRoomRoof: 'Control Room<br/>Roof',
          upperBridgeRoof: 'Upper Bridge<br/>Roof',
          // 2F
          coastGuardRoof: 'Coast Guard<br/>Roof',
          balconyRenovations: 'Balcony<br/>Renovations',
          bridgeRoof: 'Bridge Roof',
          balcony: 'Balcony',
          renovations: 'Renovations',
          roofEntrance: 'Roof<br/>Entrance',
          controlRoomHallway: 'Control Room Hallway',
          radarRoom: 'Radar<br/>Room',
          serverRoom: 'Server<br/>Room',
          serverRoomCorridor: 'Server<br/>Room<br/>Corridor',
          printerRoom: 'Printer Room',
          controlRoom: 'Control<br/>Room',
          upperBridge: 'Upper Bridge',
          // 1F
          archives: 'Archives',
          reception: 'Reception',
          modelRoom: 'Model Room',
          museum: 'Museum',
          coastGuardMeetingRoom: 'Coast Guard<br/>Meeting Room',
          radioRoomHallway: 'Radio<br/>Room<br/>Hallway',
          lowerBridge: 'Lower Bridge',
          mapRoomCorridor: 'Map Room Corridor',
          securityRoom: 'Security<br/>Room',
          lounge: 'Lounge',
          mapRoom: 'Map<br/>Room',
          kitchen: 'Kitchen',
          constructionEntrance: 'Construction<br/>Entrance',
          radioRoom: 'Radio<br/>Room',
          projectorRoom: 'Projector<br/>Room',
          // Basement
          pipes: 'Pipes',
          supplyRoomCorridor: 'Supply<br/>Room<br/>Corridor',
          bathroom: 'Bathroom',
          supplyRoom: 'Supply<br/>Room',
          showerCorridor: 'Shower<br/>Corridor',
          kayaks: 'Kayaks',
          lockerRoom: 'Locker<br/>Room',
          divingRoom: 'Diving<br/>Room',
          tunnel01: 'Tunnel 01'
        },
        kafe: {
          // EXT/Spawn
          backAlley: 'Back Alley',
          park: 'Park',
          christmasMarket: 'Christmas<br/>Market',
          garage: 'Garage',
          bakeryParking: 'Bakery<br/>Parking',
          bakeryRoof: 'Bakery Roof',
          terrace: 'Terrace',
          westMainStreet: 'West Main Street',
          mainStreet: 'Main Street',
          eastMainStreet: 'East Main Street',
          riverDocks: 'River Docks',
          // Stairs
          whiteStairs: 'White<br/>Stairs',
          redStairs: 'Red<br/>Stairs',
          // 4F/Roof
          cafeRoofTop: 'Cafe Roof Top',
          // 3F
          cigarLounge: 'Cigar<br/>Lounge',
          whiteCorridor: 'White Corridor',
          washrooms: 'Wash-<br/>rooms',
          barFreezer: 'Bar<br/>Freezer',
          cocktailLoungeEntrance: 'Cocktail<br/>Lounge<br/>Entrance',
          cocktailLounge: 'Cocktail<br/>Lounge',
          barBackstore: 'Bar<br/>Backstore',
          cigarShop: 'Cigar<br/>Shop',
          bar: 'Bar',
          cigarBalcony: 'Cigar<br/>Balcony',
          cocktailBalcony: 'Cocktail<br/>Balcony',
          // 2F
          trainMuseum: 'Train<br/>Museum',
          fireplaceHall: 'Fireplace<br/>Hall',
          readingRoomCorridor: 'Reading Room Corridor',
          laundryRoom: 'Laundry<br/>Room',
          readingRoom: 'Reading<br/>Room',
          miningRoom: 'Mining<br/>Room',
          mainCorridor: 'Main<br/>Corridor',
          pillarRoom: 'Pillar Room',
          museumEntrance: 'Museum Enrance',
          // 1F
          vipCorridor: 'VIP<br/>Corridor',
          bakery: 'Bakery',
          prepRoom: 'Prep<br/>Room',
          kitchenCooking: 'Kitchen<br/>Cooking',
          kitchenService: 'Kitchen Service',
          freezer: 'Freezer',
          vipSection: 'VIP<br/>Section',
          //mainCorridor: 'Main Corridor',
          diningRoom: 'Dining<br/>Room',
          smallBakery: 'Small<br/>Bakery',
          coatCheck: 'Coat<br/>Check',
          reception: 'Reception'
        },
        oregon: {
          // EXT
          street: 'Street',
          junkyard: 'Junkyard',
          busYard: 'Bus Yard',
          mainEntrance: 'Main Entrance',
          parking: 'Parking',
          constructionSite: 'Construction Site',
          farmlands: 'Farmlands',
          shootingRange: 'Shooting<br/>Range',

          // Stairs
          freezerStairs: 'Freezer<br/>Stairs',
          whiteStairs: 'White<br/>Stairs',
          laundryStairs: 'Laundry<br/>Stairs',
          mainStairs: 'Main<br/>Stairs',
          backStairs: 'Back<br/>Stairs',
          towerStairs: 'Tower<br/>Stairs',

          // Basement
          laundryStorage: 'Laundry<br/>Storage',
          freezer: 'Freezer',
          laundryRoom: 'Laundry Room',
          basementCorridor: 'Basement<br/>Corridor',
          supplyCloset: 'Supply<br/>Closet',
          supplyRoom: 'Supply<br/>Room',
          blueBunker: 'Blue<br/>Bunker',
          bunker: 'Bunker',
          boilerRoom: 'Boiler<br/>Room',
          electricRoom: 'Electric<br/>Room',

          // 1F
          classroom: 'Classroom',
          lobby: 'Lobby',
          showerCorridor: 'Shower Corridor',
          securityCorridor: 'Security Corridor',
          garage: 'Garage',
          smallTower: 'Small<br/>Tower',
          showers: 'Showers',
          security: 'Security',
          diningHall: 'Dining<br/>Hall',
          kitchen: 'Kitchen',
          meetingHall: 'Meeting Hall',
          split: 'Split',
          kitchenCorridor: 'Kitchen<br/>Corridor',
          rearStage: 'Rear Stage',

          // 2F
          balcony: 'Balcony',
          diningHallRoof: 'Dining<br/>Hall Roof',
          walkIn: 'Walk In',
          masterBedroom: 'Master<br/>Bedroom',
          smallTowerRoof: 'Small Tower<br/>Roof',
          dormMainHall: 'Dorm<br/>Main Hall',
          gameRoom: 'Game<br/>Room',
          armory: 'Armory',
          armoryCorridor: 'Armory<br/>Corridor',
          smallTowerOffice: 'Small Tower<br/>Office',
          trophyRoom: 'Trophy<br/>Room',
          kidsDorms: 'Kids\' Dorms',
          attic: 'Attic',
          meetingHallRoof: 'Meeting<br/>Hall<br/>Roof',
          bigTower: 'Big Tower',

          // 3F
          dormsRoof: 'Dorms Roof',
          garageRoof: 'Garage Roof',
          towerRoof: 'Tower<br/>Roof'
        },
        outback: {
          // EXT
          gasPumps: 'Gas Pumps',
          motelParkingLot: 'Motel<br/>Parking Lot',
          restaurantParking: 'Restaurant<br/>Parking',
          motelWalkway: 'Motel Walkway',
          restaurantEntrance: 'Restaurant<br/>Entrance',
          camping: 'Camping',
          loadingBay: 'Loading<br/>Bay',
          picnicArea: 'Picnic Area',
          miniGolf: 'Mini Golf',
          storageYard: 'Storage Yard',

          // 1F
          garage: 'Garage',
          waitingRoom: 'Waiting<br/>Room',
          convenienceStore: 'Convenience<br/>Store',
          beerFridge: 'Beer<br/>Fridge',
          gearStore: 'Gear<br/>Store',
          compressorRoom: 'Compressor<br/>Room',
          motelReception: 'Motel<br/>Reception',
          reptileHallway: 'Reptile<br/>Hallway',
          kitchen: 'Kitchen',
          restaurant: 'Restaurant',
          backEntrance: 'Back<br/>Entrance',
          bushrangerRoom: 'Bushranger<br/>Room',
          natureRoom: 'Nature<br/>Room',
          restaurantLobby: 'Rest-<br/>aurant<br/>Lobby',
          kitchenHallway: 'Kitchen Hallway',
          bathroomHall: 'Bath-<br/>room<br/>Hall',
          closet: 'Closet',
          bathroom: 'Bathroom',

          // 2F
          garageLounge: 'Garage<br/>Lounge',
          convenienceStoreRoof: 'Convenience<br/>Store Roof',
          motelBalcony: 'Motel Balcony',
          office: 'Office',
          officeSupplies: 'Office<br/>Supplies',
          mechanicalBull: 'Mechanical<br/>Bull',
          partyRoom: 'Party Room',
          electricalRoom: 'Electrical<br/>Room',
          gamesRoom: 'Games<br/>Room',
          dorms: 'Dorms',
          showers: 'Showers',
          mezzanine: 'Mezzanine',
          laundry: 'Laundry',
          motelHallway: 'Motel Hallway',
          pianoRoom: 'Piano<br/>Room',
          terrace: 'Terrace',

          // 3F/Roof
          restaurantRoof: 'Restaurant<br>Roof',
          garageRoof: 'Garage<br/>Roof',
          motelRoof: 'Motel<br/>Roof',

          // Stairs
          garageStairs: 'Garage<br/>Stairs',
          motelBackStairs: 'Motel<br/>Back<br/>Stairs',
          backStairs: 'Back<br/>Stairs',
          pianoStairs: 'Piano<br/>Stairs',
          restaurantStairs: 'Restaurant<br/>Stairs',
          terraceStairs: 'Terrace Stairs'
        },
        plane: {
          spawnOfficialEntrance: 'Official Entrance',
          spawnReporterEntrance: 'Reporter Entrance',
          spawnServiceEntrance: 'Service Entrance',
          pressBathroom: 'Pr.BR',
          meetingRoom: 'Meeting Room',
          frontHallway: 'Front<br/>Hallway',
          executiveOffice: 'Executive<br/>Office',
          mainEntrance: 'Main Entrance',
          frontStairs: 'Front Stairs',
          pantry: 'Pantry',
          kitchen: 'Kitchen',
          executiveHallway: 'Executive Hallway',
          executiveBedroom: 'Executive<br/>Bedroom',
          changeRoom: 'Change<br/>Room',
          laund: 'Laund.',
          frontServiceEntrance: 'Front Service<br/>Entrance',
          rightWing: 'Right Wing',
          backServiceEntrance: 'Back Service<br/>Entrance',
          reporterEntrance: 'Reporter Entrance',
          leftWing: 'Left Wing',
          staffSection: 'Staff Section',
          securityRoom: 'Security<br/>Room',
          pressSectionA: 'Press<br/>Section A',
          pressSectionB: 'Press<br/>Section B',
          backStairs: 'Back Stairs',
          cargoHold: 'Cargo Hold',
          serviceCorridor: 'Service Corridor',
          storage: 'Storage',
          luggageHold: 'Luggage<br/>Hold',
          firstAidStation: 'First Aid Station',
          cargoFrontEntrance: 'Cargo Front<br/>Entrance',
          cockpitStairs: 'Cockpit Stairs',
          cabinStaff: 'Cabin<br/>Staff',
          radioCabin: 'Radio Cabin',
          cabin: 'Cabin',
          caterer: 'Caterer',
          serverRoomA: 'Server Room A',
          serverRoomB: 'Server Room B',
          technicalSeating: 'Technical Seating',
          ladderRoom: 'Ladder<br/>Room'
        },
        skyscraper: {
          helipad: 'Helipad',
          tower: 'Tower',
          ventilationUnits: 'Ventilation Units',
          kitchen: 'Kitchen',
          pantry: 'Pantry',
          deliveryRoom: 'Delivery<br/>Room',
          houseLobby: 'House Lobby',
          houseEntrance: 'House<br/>Entrance',
          mainEntrance: 'Main<br/>Entrance',
          reception: 'Reception',
          bedroom: 'Bedroom',
          closet: 'Closet',
          bathroom: 'Bathroom',
          houseStairs: 'House<br/>Stairs',
          restaurant: 'Restaurant',
          toilet: 'Toilet',
          bbq: 'BBQ',
          backHallway: 'Back Hallway',
          mainStairs: 'Main<br/>Stairs',
          geishaRoom: 'Geisha<br/>Room',
          hallway: 'Hallway',
          karaoke: 'Karaoke',
          teaRoom: 'Tea Room',
          taiko: 'Taiko',
          terrace: 'Terrace',
          backStairs: 'Back Stairs',
          houseBalcony: 'House Balcony',
          exhibition: 'Exhibition',
          lounge: 'Lounge',
          workOffice: 'Work<br/>Office',
          clearance: 'Clearance',
          peacefullTree: 'Peaceful<br/>Tree',
          contemplationGarden: 'Contemplation<br/>Garden',
          westGarden: 'West<br/>Garden',
          bridge: 'Bridge',
          gazeebo: 'Gazebo',
          restBalcony: 'Rest.<br/>Balcony',
          northGarden: 'North<br/>Garden',
          eastGarden: 'East<br/>Garden',
          sandGarden: 'Sand<br/>Garden',
          sidePath: 'Side<br/>Path',
          sideStairs: 'Side<br/>Stairs',
          dragonStatue: 'Dragon<br/>Statue',
          coveredWalkway: 'Covered<br/>Walkway',
          drums: 'Drums',
          geisha: 'Geisha',
          makeupRoom: 'Makeup Room',
          shrineBalcony: 'Shrine<br/>Balcony',
          officeBalcony: 'Office Balcony',
          geishaBalcony: 'Geisha Balcony',
          terraceBalcony: 'Terrace Balcony',
          restaurantStairs: 'Restaurant<br/>Stairs',
          mezzanine: 'Mezzanine',
          shrine: 'Shrine',
          dragon: 'Dragon',
          office: 'Office',
          exhibitionRoom: 'Exhibition Room',
          display: 'Display',
          vipClearance: 'VIP Clearance',
          bar: 'Bar',
          sushiBar: 'Sushi Bar',
          coatCheck: 'Coat Check',
          delivery: 'Delivery',
          walkIn: 'Walk-In',
          bedroomCloset: 'Bedroom<br/>Closet',
          sideEntrance: 'Side<br/>Entrance'
        },
        themepark: {
          // EXT
          mainEntrance: 'Main Entrance',
          palms: 'Palms',
          roboCircuit: 'Robo Circuit',
          castleEntrance: 'Castle<br/>Entrance',
          bumperCars: 'Bumper<br/>Cars',
          guestInfo: 'Guest<br/>Info',
          village: 'Village',
          sweetShop: 'Sweet<br/>Shop',
          serviceEntrance: 'Service Entrance',
          teacups: 'Teacups',
          backAlley: 'Back Alley',

          // 1F
          arcadeEntrance: 'Arcade<br/>Entrance',
          barrelRoom: 'Barrel<br/>Room',
          throneRoom: 'Throne<br/>Room',
          maintenance: 'Maintenance',
          dragonStairs: 'Dragon<br/>Stairs',
          blueRoom: 'Blue<br/>Room',
          jointCorridor: 'Joint<br/>Corridor',
          armory: 'Armory',
          lab: 'Lab',
          redCorridor: 'Red<br/>Corridor',
          gongRoom: 'Gong<br/>Room',
          yellowCorridor: 'Yellow<br/>Corridor',
          tellers: 'Tellers',
          storage: 'Storage',
          arcadeToilet: 'Arcade<br/>Toilet',
          lockerRoom: 'Locker<br/>Room',

          // 2F
          upperArcade: 'Upper<br/>Arcade',
          breakRoom: 'Break Room',
          initiationRoom: 'Initiation<br/>Room',
          controlRoom: 'Control<br/>Room',
          bunk: 'Bunk',
          dayCare: 'Day<br/>Care',
          // yellow corridor, but 2F /shrug
          waitingRoom: 'Waiting<br/>Room',
          cafeTerrace: 'Cafe<br/>Terrace',
          cashCorridor: 'Cash Corridor',
          office: 'Office',
          cashStash: 'Cash<br/>Stash',
          cafeCorridor: 'Cafe Corridor',
          cafe: 'Cafe',
          officeShower: 'Office<br/>Showers',
          officeVault: 'Office<br/>Vault',

          // Roof
          roof: 'Roof',

          // Stairs
          arcadeStairs: 'Arcade<br/>Stairs',
          yellowStairs: 'Yellow<br/>Stairs'
        },
        tower: {
          // EXT/Spawn
          northRoof: 'North Roof',
          northRoofNotShown: 'North Roof<br/>(not shown)',
          southRoof: 'South Roof',
          southRoofNotShown: 'South Roof<br/>(now shown)',
          roofAccess: 'Roof<br/>Access',
          northRappel: 'North<br/>Rappel',
          southRappel: 'South<br/>Rappel',
          // 3F
          mezzanine: 'Mezzanine',
          ventilation: 'Ventilation',
          // 2F
          centerAtrium: 'Center<br/>Atrium',
          infoBooth: 'Info Booth',
          eastAtrium: 'East<br/>Atrium',
          westAtrium: 'West<br/>Atrium',
          eastBalcony: 'East<br/>Balcony',
          westBalcony: 'West<br/>Balcony',
          galleryMain: 'Gallery<br/>Main',
          mediaCenter: 'Media<br/>Center',
          galleryAnnex: 'Gallery<br/>Annex',
          giftShop: 'Gift<br/>Shop',
          exhibitRoom: 'Exhibit<br/>Room',
          lanternRoom: 'Lantern<br/>Room',
          elevator: 'Elevator',
          officesHallway: 'Offices Hallway',
          exhibitHallway: 'Exhibit Hallway',
          offices: 'Offices',
          companyReception: 'Company<br/>Reception',
          supplyRoom: 'Supply<br/>Room',
          meetingRoom: 'Meeting<br/>Room',
          ceoOffice: 'CEO\'s<br/>Office',
          bathroom: 'Bathroom',
          serverRoom: 'Server<br/>Room',
          // 2F to 1F Stairs
          northStairs: 'North Stairs',
          westStairs: 'West<br/>Stairs',
          eastStairs: 'East<br/>Stairs',
          // 1F
          mainReception: 'Main<br/>Reception',
          centerHallway: 'Center<br/>Hallway',
          teaRoom: 'Tea Room',
          lounge: 'Lounge',
          bar: 'Bar',
          kitchen: 'Kitchen',
          restaurant: 'Restaurant',
          birdRoom: 'Bird<br/>Room',
          restaurantReception: 'Restaurant<br/>Reception',
          restaurantHallway: 'Restaurant Hallway',
          barHallway: 'Bar Hallway',
          westObservatory: 'West<br/>Observatory',
          bonsaiRoom: 'Bonsai<br/>Room',
          traditionalHall: 'Traditional Hall',
          gameRoom: 'Game<br/>Room',
          eastObservatory: 'East<br/>Observatory',
          fountain: 'Fountain',
          //Air Ducts:
          northAirDuct: 'North<br/>Air Duct',
          eastAirDuct: 'East<br/>Air Duct',
          westAirDuct: 'West<br/>Air Duct'
        },
        villa: {
          // South->North, West->East
          // Spawn
          spawnMainRoad: 'Main Road',
          spawnRuins: 'Ruins',
          spawnFountain: 'Fountain',
          // EXT
          ruins: 'Ruins',
          roundabout: 'Roundabout',
          greenhouse: 'Greenhouse',
          frontEntrance: 'Front Entrance',
          roof: 'Roof',
          terrace: 'Terrace',
          cryptTunnel: 'Crypt Tunnel',
          crypt: 'Crypt',
          cryptEntrance: 'Crypt<br/>Entrance',
          garden: 'Garden',
          driveway: 'Driveway',
          mainRoad: 'Main Road',
          balcony: 'Balcony',
          pergola: 'Pergola',
          stableYard: 'Stable Yard',
          skylightRoof: 'Skyligh<br/>Roof',
          bedroomRoof: 'Bedroom Roof',
          chapel: 'Chapel',
          garage: 'Garage',
          fountain: 'Fountain',
          stable: 'Stable',
          cellarTunnel: 'Cellar Tunnel',
          sideRoad: 'Side Road',
          // 2F
          veranda: 'Veranda',
          classicalHall: 'Classical Hall',
          study: 'Study',
          gamesRoom: 'Games Room',
          aviatorRoom: 'Aviator Room',
          huntingVault: 'Hunting Vault',
          landing: 'Landing',
          trophyEntrance: 'Trophy<br/>Entrance',
          statuaryRoom: 'Statuary Room',
          trophyRoom: 'Trophy<br/>Room',
          masterBedroom: 'Master Bedroom',
          astronomyRoom: 'Astronomy<br/>Room',
          masterBathroom: 'Master<br/>Bathroom',
          walkInCloset: 'Walk-In<br/>Closet',
          // 1F
          mainEntrance: 'Main Entrance',
          artStudio: 'Art Studio',
          library: 'Library',
          mainHallway: 'Main<br>Hallway',
          pianoRoom: 'Piano Room',
          gallery: 'Gallery',
          toilet: 'Toilet',
          livingRoom: 'Living Room',
          bicycleStorage: 'Bicycle Storage',
          memorialRoom: 'Memorial<br/>Room',
          mudroom: 'Mudroom',
          skylightHallway: 'Skylight<br/>Hallway',
          laundry: 'Laundry',
          backHallway: 'Back<br/>Hallway',
          diningRoom: 'Dining Room',
          chinaRoom: 'China<br/>Room',
          kitchen: 'Kitchen',
          pantry: 'Pantry',
          // B
          tastingRoom: 'Tasting Room',
          archHallway: 'Arch<br/>Hallway',
          artStorage: 'Art Storage',
          oldOffice: 'Old Office',
          wineCellar: 'Wine Cellar',
          // Stairs
          mainStairs: 'Main Stairs',
          redStairs: 'Red<br/>Stairs',
          backStairs: 'Back<br/>Stairs',
          pantryStairs: 'Pantry<br/>Stairs'
        },
        yacht: {
          spawnSubmarine: 'Submarine',
          spawnZodiak: 'Zodiak',
          spawnSnowMobile: 'Snow Mobile',
          mapsRoom: 'Maps Room',
          cockpit: 'Cockpit',
          cockpitHallway: 'Cockpit Hallway',
          captainsOffice: 'Captain\'s<br/>Office',
          cockpitBalcony: 'Cockpit<br/>Balcony',
          topDeckStairs: 'Top<br/>Deck<br/>Stairs',
          helipadEntrance: 'Helipad<br/>Entrance',
          helipad: 'Helipad',
          spaDeck: 'Spa<br/>Deck',
          eastDeck: 'East Deck',
          westDeck: 'West Deck',
          frontDeck: 'Front<br/>Deck',
          masterBedroom: 'Master<br/>Bedroom',
          casino: 'Casino',
          pokerRoom: 'Poker<br/>Room',
          bathroom: 'Bath-<br/>room',
          bedroomHallway: 'Bedroom Hallway',
          casinoHallway: 'Casino Hallway',
          globeHallway: 'Globe<br/>Hallway',
          lounge: 'Lounge',
          cafeteria: 'Cafeteria',
          engine: 'Engine',
          backEntrance: 'Back<br/>Entrance',
          rearDeck: 'Rear<br/>Deck',
          serverRoom: 'Server<br/>Room',
          engineStorage: 'Engine<br/>Storage',
          engineControl: 'Engine<br/>Control',
          backStairs: 'Back<br/>Stairs',
          emergencyExit: 'Emergency Exit',
          engineHallway: 'Engine Hallway',
          frontStairs: 'Front<br/>Stairs',
          kitchen: 'Kitchen',
          staffDormitory: 'Staff<br/>Dormitory',
          westBalcony: 'West Balcony',
          eastBalcony: 'East Balcony',
          kitchenHallway: 'Kitchen Hallway',
          kitchenStairs: 'Kitchen Stairs',
          kitchenPantry: 'Kitchen<br/>Pantry',
          infirmary: 'Infirmary',
          borealSubRoom: 'Boreal Sub<br/>Room',
          cafeteriaHallway: 'Cafeteria Hallway',
          engineUtility: 'Engine<br/>Utility',
          submarine: 'Submarine',
          westGlacier: 'West Glacier',
          eastHullBreach: 'East Hull<br/>Breach',
          eastGlacier: 'East Glacier',
          frozenRiver: 'Frozen River',
          zodiac: 'Zodiac',
          westHullBreach: 'West Hull Breach',
          kingOfTheWorld: 'King of<br/>the World',
          roof: 'Roof',
          anchorName: 'Anchor',
          aklarkSubEntrance: 'Aklark Sub<br/>Entrance'
        }
      }
    };

  var getLoadedLang = function getLoadedLang() {
    return loadedLang;
  };

  var getLoadedDirection = function getLoadedDirection() {
    return loadedDirection;
  };

  var registerLanguage = function registerLanguage(language, terms, direction) {
    translations[language] = {
      terms: terms,
      direction: (direction === 'RTL') ? 'RTL' : 'LTR'
    };
  };

  var tryLoadLanguage = function tryLoadLanguage(language) {
    if (translations[language]) {
      loadTerms(translations[language].terms);
      loadedLang = language;
      loadedDirection = translations[language].direction;
    }
  };

  var loadTerms = function loadTerms(newTerms) {
    loadDefaultTerms();
    $.extend(true, terms, newTerms);
  };

  var loadDefaultTerms = function loadDefaultTerms() {
    $.extend(true, terms, englishTerms);
  };

  registerLanguage(name, englishTerms);
  loadDefaultTerms();

  return  {
    name: name,
    getLoadedLang: getLoadedLang,
    getLoadedDirection: getLoadedDirection,
    terms: terms,
    registerLanguage: registerLanguage,
    tryLoadLanguage: tryLoadLanguage,
    loadedLanguages: translations
  };
})();
